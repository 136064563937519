import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import ApartmentInfoScreen from "../screens/ApartmentInfoScreen";
import ScrollToTop from "../components/ScrollToTop";

import tinyHausImages from "../assets/images/tiny-haus/tinyHausImages";
import wgZimmerImages from "../assets/images/wg-zimmer/wgZimmerImages";
import apartmentsImages from "../assets/images/apartments/apartmentsImages";
import aappartraumImages from "../assets/images/aappartraum/aappartraumImages";
import apartmentsEngerImages from "../assets/images/apartments-enger/apartmentsEngerImages";
import postServiceImages from "../assets/images/post-service/postServiceImages";

const apartments = [
  {
    id: "tiny-haus",
    title: "Tiny Haus in Herford",
    subheading: "mit separaten Eingang für max. 2 Personen mit Doppelbett",
    address: "Mindenerstrasse 1, 32049 Herford",
    occupancy: "2 Personen, 1 Doppelbett",
    details: [
      "Das besondere kleine Haus liegt separat und zentral. Die voll ausgestattete Küche mit Backofen, Cerankochfeld und kleiner Kühl- und Gefrierkombo, einschließlich Kaffeemaschine und Toaster, lässt keine Wünsche offen. Ein privates Badezimmer mit Dusche finden Sie ebenfalls in diesem Raum Wunder.",
      "Der separate Eingang mit Klingel bietet zusätzliche Privatsphäre. Zudem stehen Münz-Waschmaschinen und Trockner im Untergeschoss jederzeit zur Verfügung, um den Komfort des kleinen Hauses abzurunden.",
    ],
    price: "Ab nur 79 EUR pro Nacht",
    images: tinyHausImages,
  },
  {
    id: "wg-zimmer",
    title: "WG-Zimmer Bielefeld Phoenix Haus",
    subheading: "Für 2, 3 oder 4 Personen geeignet",
    address:
      "Altenhagenersstr. 285, 33729 Bielefeld (Nebeneingang China Restaurant)",
    occupancy: "2, 3, oder 4 Personen",
    details: [
      "Unsere WG-Zimmer zur Kurz- und Langzeitmiete sind ideal für Studenten oder Alleinreisende. Jedes Zimmer ist gut ausgestattet und abschließbar, um eine sichere Umgebung zu gewährleisten.",
      "Die Zimmer teilen sich eine voll ausgestattete Gemeinschaftsküche und ein großes Gemeinschaftsbad mit Waschmaschine, was eine komfortable und gemeinschaftliche Atmosphäre schafft.",
    ],
    price: "Ab nur 49 EUR pro Nacht",
    images: wgZimmerImages,
  },
  {
    id: "apartments",
    title: "Apartments Herford",
    subheading: "Luxuriöses Erdgeschoss Apartment für bis zu 5 Personen",
    address: "Mindenerstrasse 1, 32049 Herford",
    occupancy: "bis 5 Personen (1 Doppelbett, 1 Etagenbett, 1 Schlafsofa)",
    details: [
      "Unsere geräumigen und luxuriösen Erdgeschoss Apartments in Herford bieten ausreichend Platz für bis zu fünf Personen und sind rollstuhlgerecht gebaut. Sie verfügen über eine moderne Küche, die mit Elektrogeräten wie Mikrowelle, Kaffeemaschine, Backofen mit Herd, Dunstabzugshaube und großem Kühlschrank ausgestattet ist.",
      "Jedes Apartment bietet ausgezeichnetes Internet und einen Smart TV für Unterhaltung. Außerdem ist eine eigene Waschmaschine im Badezimmer vorhanden, die den Aufenthalt noch bequemer gestaltet.",
    ],
    price: "Ab nur 89 EUR pro Nacht",
    images: apartmentsImages,
  },
  {
    id: "aappartraum",
    title: "Aappartraum Herford",
    subheading: "Geräumiges Apartment für bis zu 6 Personen im Untergeschoss",
    address: "Mindenerstrasse 1, 32049 Herford",
    occupancy: "bis 6 Personen (2 Einzelbetten, 2 Etagenbetten)",
    details: [
      "Das Aappartraum bietet großzügigen Platz für bis zu sechs Personen und ist optimal für größere Gruppen geeignet. Die Schlafbereiche sind voneinander getrennt, und eine separate Küche, ein WC sowie eine Dusche runden das komfortable Angebot ab.",
      "Besonders hervorzuheben ist die ruhige Lage im Untergeschoss, die eine angenehme und entspannte Atmosphäre schafft – ideal für Erholung nach einem langen Tag.",
    ],
    price: "Ab nur 89 EUR pro Nacht",
    images: aappartraumImages,
  },
  {
    id: "apartments-enger",
    title: "2 Zimmer Apartments Enger",
    subheading: "Luxuriöses Erdgeschoss Apartment für bis zu 5 Personen",
    address: "Jöllenbeckestr. 211, 32130 Enger",
    occupancy: "bis 5 Personen (1 Doppelbett, 1 Etagenbett, 1 Schlafsofa)",
    details: [
      "Unsere großzügigen 2-Zimmer Apartments befinden sich in Enger, ideal gelegen zwischen Bielefeld und Herford. Die Apartments bieten je zwei separate Schlafzimmer, die Privatsphäre für alle Übernachtenden ermöglichen, sowie ein privates Badezimmer mit Badewanne oder Dusche.",
      "Apartment A und B teilen sich eine zentrale Gemeinschaftsküche, während Apartment C im Dachgeschoss über eine eigene Küche für die drei Schlafzimmer verfügt. Ein eigener Parkplatz vor der Tür und der gemeinschaftlich nutzbare Garten mit Grillplatz sorgen für zusätzlichen Komfort und Erholungsmöglichkeiten.",
    ],
    price: "Ab nur 89 EUR pro Nacht",
    images: apartmentsEngerImages,
  },
  {
    id: "post-service",
    title: "Post Service",
    subheading: "Sicher und zuverlässig",
    address: "Poststraße 3, 32052 Herford",
    occupancy: "Für geschäftliche oder private Zwecke",
    details: [
      "Unser Post Service bietet Ihnen die Möglichkeit, Ihre Post sicher zu empfangen, auch wenn Sie unterwegs sind. Dies ist ideal für vielbeschäftigte Reisende, die sicherstellen möchten, dass ihre Post nicht verloren geht.",
      "Wir bieten flexible Postdienstleistungen sowohl für geschäftliche als auch private Zwecke, um Ihnen den Alltag zu erleichtern.",
    ],
    price: "Ab nur 29 EUR pro Monat",
    images: postServiceImages,
  },
];

const AppNavigator = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        {apartments.map((apartment) => (
          <Route
            key={apartment.id}
            path={`/apartment-info/${apartment.id}`}
            element={<ApartmentInfoScreen apartment={apartment} />}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default AppNavigator;
