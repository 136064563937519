import "./App.scss";
import CookieBanner from "./components/CookieBanner";

import Footer from "./components/Footer";
import Header from "./components/Header";
import AppNavigator from "./navigator/AppNavigator";
import ApartmentInfoScreen from "./screens/ApartmentInfoScreen";

function App() {
  return (
    <div className="app">
      <AppNavigator />
      <CookieBanner />
    </div>
  );
}

export default App;
