import React from "react";
import { Link } from "react-router-dom"; // Импортируем Link для навигации

import tinyHausImages from "../../assets/images/tiny-haus/tinyHausImages";
import wgZimmerImages from "../../assets/images/wg-zimmer/wgZimmerImages";
import apartmentsImages from "../../assets/images/apartments/apartmentsImages";
import aappartraumImages from "../../assets/images/aappartraum/aappartraumImages";
import apartmentsEngerImages from "../../assets/images/apartments-enger/apartmentsEngerImages";
import postServiceImages from "../../assets/images/post-service/postServiceImages";

const pricesCards = [
  {
    name: "Tiny Haus",
    price: "79,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: tinyHausImages[1],
    altText: "Studio",
    link: "/apartment-info/tiny-haus",
  },
  {
    name: "WG-Zimmer",
    price: "49,00 €",
    period: "/pro Tag",
    buttonLabel: "Anfragen",
    image: wgZimmerImages[7],
    altText: "WG-Zimmer",
    link: "/apartment-info/wg-zimmer",
  },
  {
    name: "Apartments Herford",
    price: "89,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: apartmentsImages[6],
    altText: "Apartment Herford",
    link: "/apartment-info/apartments",
  },
  {
    name: "Aappartraum",
    price: "89,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: aappartraumImages[2],
    altText: "Aappartraum Herford",
    link: "/apartment-info/aappartraum",
  },
  {
    name: "Apartments Enger",
    price: "89,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: apartmentsEngerImages[2],
    altText: "Apartments Enger",
    link: "/apartment-info/apartments-enger",
  },
  {
    name: "Post Service",
    price: "ab 29,00 €",
    period: "/pro Monat",
    buttonLabel: "Anfragen",
    image: postServiceImages[1],
    altText: "Box Storage",
    link: "/apartment-info/post-service",
  },
];

const OurPricesSection = () => {
  return (
    <section className="our-prices-section">
      <h2>Unsere Preise</h2>
      <div className="prices-cards">
        {pricesCards.map((priceCard) => (
          <div key={priceCard.name} className="price-card">
            <img src={priceCard.image} alt={priceCard.altText} />
            <div className="price-info">
              <h3>{priceCard.name}</h3>
              <p className="price">
                {priceCard.price}{" "}
                <span className="period">{priceCard.period}</span>
              </p>
              <button>{priceCard.buttonLabel}</button>
              <p className="tax-info">Preis inkl. MwSt.</p>
            </div>
            <Link to={priceCard.link} className="mehr-erfahren-link">
              Mehr erfahren
            </Link>{" "}
            {/* Используем Link для навигации */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurPricesSection;
