import React from "react";
import { FaMapMarkerAlt, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaAngleLeft
      className={className + " arrow-icon"}
      style={{ ...style, display: "block", color: "#333", fontSize: "24px" }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaAngleRight
      className={className + " arrow-icon"}
      style={{ ...style, display: "block", color: "#333", fontSize: "24px" }}
      onClick={onClick}
    />
  );
};

const ApartmentInfoScreen = ({ apartment }) => {
  const { title, subheading, address, occupancy, details, price, images } =
    apartment;

  const googleMapsEmbedLink = `https://www.google.com/maps?q=${encodeURIComponent(
    address
  )}&output=embed`;

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="apartment-info-screen">
      <Header />
      <section className="apartment-info">
        <h2 className="title">{title}</h2>
        <div className="address">
          <FaMapMarkerAlt className="icon" />
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              address
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address}
          </a>
        </div>

        <div className="horizontal-blocks">
          <Slider {...sliderSettings} className="apartment-slider">
            {images.map((img, index) => (
              <div key={index}>
                <img
                  src={img}
                  alt={`Slide ${index + 1}`}
                  className="apartment-image"
                />
              </div>
            ))}
          </Slider>

          <div className="info-block">
            <div className="details">
              <h3>{title}</h3>
              {details.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>

            <div className="occupancy">
              <strong>Maximale Belegung:</strong> {occupancy}
            </div>

            <div className="price">
              <strong>Preis:</strong> {price}
            </div>
          </div>
          <div className="map-container">
            <iframe
              title="Google Maps"
              src={googleMapsEmbedLink}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ApartmentInfoScreen;
