import React from "react";
import { FiClock, FiCalendar, FiUser } from "react-icons/fi";

const BookSection = () => {
  return (
    <section className="book-section">
      <h2>Jetzt buchen</h2>
      <div className="book-controls">
        <div className="control">
          <FiClock />
          <select>
            <option>1 Nacht</option>
            <option>2 Nächte</option>
            <option>3 Nächte</option>
          </select>
        </div>
        <div className="control">
          <FiCalendar />
          <input type="text" placeholder="Anreisedatum" />
        </div>
        <div className="control">
          <FiUser />
          <input type="text" placeholder="1 Zimmer / 2 Erw. / 0 Kinder" />
        </div>
        <button>Weiter</button>
      </div>
    </section>
  );
};

export default BookSection;
